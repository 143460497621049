import React from 'react';

const Step1 = ({ formData, handleChange }) => {
  return (
    <div>
      <h3>Step 1</h3>
      <input type="text" placeholder="Your Name" value={formData.name} onChange={handleChange('step1Input1')} />
      <input type="email" placeholder="Your Email Address" value={formData.email} onChange={handleChange('step1Input2')} />
      <input type="age" placeholder="Your Age" value={formData.age} onChange={handleChange('step1Input3')} />
    </div>
  );
};

export default Step1;
