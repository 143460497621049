import React, { useState } from 'react'
import "./Multisteps.css"

import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3 from './components/Step3';



const Multisteps = () => {
  return <Multi/>
}



function Multi (){

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    age: '',
    step2Input1: '',
    step2Input2: '',
    step2Input3: '',
    step3Input1: '',
    step3Input2: '',
    step3Input3: '',
    step3Input4: '',
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (input) => (e) => {
    setFormData({ ...formData, [input]: e.target.value });
  };


  const handleSubmit = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(formData))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = 'formData.json';

    link.click();
  };

return <div className='container'>
    <div className='progress_container'>
    {/* <h1>Multiforms</h1> */}
    <div className='progress'></div>
    <div className={step === 1 ? "circle active" : "circle"}>1</div>
    <div className={step === 2 ? "circle active" : "circle"}>2</div>
    <div className={step === 3 ? "circle active" : "circle"}>3</div>
    </div>
    <div className='inputcontainer'>
    {step === 1 && <Step1 formData={formData} handleChange={handleChange} />}
      {step === 2 && <Step2 formData={formData} handleChange={handleChange} />}
      {step === 3 && <Step3 formData={formData} handleChange={handleChange} />}
    </div>
    
    {/* <div className='content'> <h2><Message step = {step} /></h2></div> */}
    <div className='btns'>
    {step > 1 && <div onClick={prevStep} className='btn'>Prev</div> }
    {step < 3 && <div onClick={nextStep } className='btn active'>Next</div>}
    {step === 3 && <div className='btn' onClick={handleSubmit}>Submit</div>}

    </div>

    

</div>

}


export default Multisteps;