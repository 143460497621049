import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import "./App.css";

import Multisteps from './Multisteps';

function App() {
  return (
    <div className="App">
      <h1>Multi-Step Form</h1>

      
      <Routes>
  <Route path='/' element ={<Multisteps/>}/>
    </Routes>
    </div>
  );
}

export default App;
