import React from 'react';

const Step3 = ({ formData, handleChange }) => {
  return (
    <div>
      <h3>Step 3</h3>
      <input type="text" placeholder="Input 1" value={formData.step3Input1} onChange={handleChange('step3Input1')} />
      <input type="text" placeholder="Input 2" value={formData.step3Input2} onChange={handleChange('step3Input2')} />
      <input type="text" placeholder="Input 3" value={formData.step3Input3} onChange={handleChange('step3Input3')} />
      <input type="text" placeholder="Input 4" value={formData.step3Input4} onChange={handleChange('step3Input4')} />
    </div>
  );
};

export default Step3;
