import React from 'react';

const Step2 = ({ formData, handleChange }) => {
  return (
    <div>
      <h3>Step 2</h3>
      <input type="text" placeholder="Input 1" value={formData.step2Input1} onChange={handleChange('step2Input1')} />
      <input type="text" placeholder="Input 2" value={formData.step2Input2} onChange={handleChange('step2Input2')} />
      <input type="text" placeholder="Input 3" value={formData.step2Input3} onChange={handleChange('step2Input3')} />
    </div>
  );
};

export default Step2;
